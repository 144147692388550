<template>
  <iframe
    :width="iframe.width"
    :height="iframe.height"
    src="https://lookerstudio.google.com/embed/reporting/50708f10-308d-44ba-a2a3-bd9bd2f53574/page/p_1vzywyx04c"
    frameborder="0"
    style="border: 0"
    allowfullscreen
  ></iframe>
</template>

<script>
import { sync, dispatch } from "vuex-pathify";
export default {
  computed: {
    ...sync("brgy", ["brgy"]),
  },
  data() {
    return {
      iframe: {
        height: 0.95 * window.innerHeight,
        width: "100%",
      },
      headers: [
        {
          text: "Barangays",
          align: "start",
          sortable: false,
          value: "name",
        },
      ],
    };
  },

  beforeMount() {
    this.getBrgyData();
  },

  methods: {
    getBrgyData() {
      dispatch("brgy/brgyload");
    },
  },
};
</script>
